<template lang="pug">
v-tooltip(top max-width="400").tooltip
  template(v-slot:activator="{ on }")
    div( v-on="on" :style="{width: width, height: height}" :class="classes" @click="$emit('click')").tooltip__text {{ text }}
  span {{ text }}
</template>

<script>
export default {
  props: {
    text: {
      required: true,
    },

    height: String,
    width: String,
    classes: Array
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  &__text {
    overflow: hidden;
  }
}
</style>